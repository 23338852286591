import React, { Suspense } from "react";
import { Route, Switch } from "react-router";

const Switcher = ({ routes }) => (
  <Suspense fallback={<div></div>}>
    <Switch>
      {routes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
    </Switch>
  </Suspense>
);

export default Switcher;
