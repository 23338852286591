import React, { useEffect } from "react";
import useAuth from "../contexts/auth";

export function withRoleRoute(Route, roles = []) {
  return ({ ...props }) => {
    const { user } = useAuth();
    const canAccess = roles.includes(user.access_type);
    if (!canAccess) window.location.replace("/");

    if (canAccess) return <Route {...props} />;
    return null;
  };
}

export default function withPrivateRoute(Route) {
  return ({ ...props }) => {
    const { user, isAuthenticated, loading, loginUrl } = useAuth();

    useEffect(() => {
      if (!isAuthenticated && !loading) window.location.replace(loginUrl);
    }, [loading, isAuthenticated, loginUrl]);

    if (isAuthenticated && user) return <Route {...props} />;
    return null;
  };
}
