import React from "react";
import { Space } from "antd";

const Circle = ({ size, color, ...props }) => (
  <svg
    {...props}
    height={`${size}`}
    width={`${size}`}
    style={{ cursor: "pointer" }}
    role="application"
  >
    <title>progress bar dot</title>
    <ellipse
      cx={`${size / 2}`}
      cy={`${size / 2}`}
      rx={`${size / 2}`}
      ry={`${size / 2}`}
      style={{ fill: color, strokeWidth: "0px" }}
    />
  </svg>
);

function Progress({ count, onChange, value, ...props }) {
  return (
    <div className="m-t:1" {...props}>
      <Space>
        {[...Array(count).keys()].map((item, index) => (
          <Circle
            key={index}
            onClick={() => onChange(index)}
            size={16}
            color={value === index ? "yellow" : "white"}
          />
        ))}
      </Space>
    </div>
  );
}

export default Progress;
