import React from "react";
import { Avatar } from "antd";
import styled from "styled-components";
import qs from "query-string";

import generateAvatar from "../utils/generateAvatar";

const ClippedAvatar = styled(Avatar)`
  overflow: visible;
  > img {
    display: block;
    width: 110%;
    height: ${(props) => props.avatarHeight || "110%"};
    object-fit: unset;
    overflow: unset;
    top: -10%;
    position: relative;
    -webkit-clip-path: inset(0 0 0 0 round 0 0 50% 50%);
    clip-path: inset(0 0 0 0 round 0 0 50% 50%);
    left: -5%;
  }
`;

export default function UserAvatar({
  user,
  avatarSize,
  video,
  avatarHeight,
  ...props
}) {
  const avatar = user.avatar;
  const profile_image = user.profile_image;

  return (
    <div {...props}>
      {profile_image ? (
        <Avatar
          src={profile_image}
          size={avatarSize}
          className={video && "pos:a absolute-center"}
        />
      ) : avatar ? (
        <ClippedAvatar
          src={avatar}
          size={avatarSize}
          style={{
            backgroundColor: qs.parse(avatar.split("?")[1]).avatarBackground,
          }}
          className={video && "pos:a absolute-center"}
          avatarHeight={avatarHeight}
        />
      ) : (
        <Avatar
          src={generateAvatar(user.first_name, user.last_name)}
          size={avatarSize}
          className={video && "pos:a absolute-center"}
        />
      )}
    </div>
  );
}
