import React, { lazy } from "react";
import { Route } from "react-router";

import withPrivateRoute, {
  withRoleRoute,
} from "../components/withPrivateRoute";
import withRedirectIfAuth from "../components/withRedirectRoute";
import withPlanVerification from "../components/withPlanVerification";
import OnboardingWrapper from "../components/Onboarding/Onboarding";
import SystemNotificationProvider from "../components/SystemNotificationProvider";
import { AuthProvider } from "../contexts/auth";
import { ResetPassword, ResetPasswordValid } from "../pages/ResetPassword";
import Switcher from "./Switcher";
import { withLocaleRoute } from "../contexts/internationalization";

const SignUp = lazy(() => import("../pages/SignUp"));
const Login = lazy(() => import("../pages/Login"));
const Home = lazy(() => import("../pages/Home"));
const Profile = lazy(() => import("../pages/Profile"));
const MyApps = lazy(() => import("../pages/MyApps"));
const ManageMembers = lazy(() => import("../pages/ManageMembers"));
const CompanySettings = lazy(() => import("../pages/CompanySettings"));
const PaymentSettings = lazy(() => import("../pages/PaymentSettings"));
const PlanSettings = lazy(() => import("../pages/PlanSettings"));
const Events = lazy(() => import("../pages/Events"));
const CreateEvent = lazy(() => import("../pages/EditEvent"));
const Event = lazy(() => import("../pages/Event"));
const Subscription = lazy(() => import("../pages/Subscription"));
const ManageExtensions = lazy(() => import("../pages/ManageExtensions"));
const FloorPlanSettings = lazy(() =>
  import("../pages/settings/OfficeSettings")
);
const AccountSettings = lazy(() => import("../pages/AccountSettings"));
const ExpiredPlan = lazy(() => import("../pages/ExpiredPlan"));
const InvitationModal = lazy(() =>
  import("../components/OfficeMap/Invitation/InvitationModal")
);
const FloorPlanEdit = lazy(() => import("../pages/settings/FloorPlanEdit"));

export const routes = [
  {
    path: "/:locale(en|pt)?/sign_up",
    component: withRedirectIfAuth({
      Route: withLocaleRoute(SignUp),
      redirectToUrl: "/",
    }),
  },
  {
    path: "/:locale(en|pt)?/login",
    component: withRedirectIfAuth({
      Route: withLocaleRoute(Login),
      redirectToUrl: "/",
    }),
  },
  {
    path: "/:locale(en|pt)?/recuperar-conta",
    component: withRedirectIfAuth({
      Route: withLocaleRoute(ResetPassword),
      redirectToUrl: "/",
    }),
    exact: true,
  },
  {
    path: "/:locale(en|pt)?/recuperar-conta/:token/:uid",
    component: withRedirectIfAuth({
      Route: withLocaleRoute(ResetPasswordValid),
      redirectToUrl: "/",
    }),
    exact: true,
  },
  {
    path: "/",
    component: withPrivateRoute(withPlanVerification(Home)),
    exact: true,
  },
  {
    path: "/profile",
    component: withPrivateRoute(withPlanVerification(Profile)),
  },
  {
    path: "/my-apps",
    component: withPrivateRoute(withPlanVerification(MyApps)),
  },
  {
    path: "/events/create",
    component: withPrivateRoute(withPlanVerification(CreateEvent)),
  },
  {
    path: "/subscription",
    component: withPrivateRoute(withRoleRoute(Subscription, ["ADMIN"])),
  },
  {
    path: "/events/create_external",
    component: withPrivateRoute(withPlanVerification(InvitationModal)),
  },
  {
    path: "/events/:id/edit",
    component: withPrivateRoute(withPlanVerification(CreateEvent)),
  },
  {
    path: "/events/:id",
    component: withPrivateRoute(withPlanVerification(Event)),
  },
  {
    path: "/events",
    component: withPrivateRoute(withPlanVerification(Events)),
  },
  {
    path: "/settings/members",
    component: withPrivateRoute(
      withPlanVerification(withRoleRoute(ManageMembers, ["ADMIN", "MANAGER"]))
    ),
  },
  {
    path: "/settings/account",
    component: withPrivateRoute(withPlanVerification(AccountSettings)),
  },
  {
    path: "/settings/payment",
    component: withPrivateRoute(
      withPlanVerification(withRoleRoute(PaymentSettings, ["ADMIN"]))
    ),
  },
  {
    path: "/settings/company",
    component: withPrivateRoute(
      withPlanVerification(withRoleRoute(CompanySettings, ["ADMIN", "MANAGER"]))
    ),
  },
  {
    path: "/settings/office/floorplans/create",
    component: withPrivateRoute(
      withPlanVerification(withRoleRoute(FloorPlanEdit, ["ADMIN", "MANAGER"]))
    ),
  },
  {
    path: "/settings/office/floorplans/:id(\\d+)",
    component: withPrivateRoute(
      withPlanVerification(withRoleRoute(FloorPlanEdit, ["ADMIN", "MANAGER"]))
    ),
  },
  {
    path: "/settings/office",
    component: withPrivateRoute(
      withPlanVerification(
        withRoleRoute(FloorPlanSettings, ["ADMIN", "MANAGER"])
      )
    ),
  },
  {
    path: "/settings/integrations",
    component: withPrivateRoute(withPlanVerification(ManageExtensions)),
  },
  {
    path: "/plan_expired",
    component: withPrivateRoute(ExpiredPlan),
  },
];

function AuthWrapper() {
  return (
    <AuthProvider>
      <OnboardingWrapper>
        <SystemNotificationProvider>
          <Switcher routes={routes} />
        </SystemNotificationProvider>
      </OnboardingWrapper>
    </AuthProvider>
  );
}

const authRoutes = routes.map((route) => (
  <Route key={route.path} {...route} component={AuthWrapper} />
));

export const generateAuthRoutes = (base) =>
  routes.map(({ path, ...route }) => {
    return (
      <Route
        key={path}
        path={`${base}${path}`}
        {...route}
        component={AuthWrapper}
      />
    );
  });

export default authRoutes;
