import React from "react";
import MDI from "@mdi/react";
import { Icon as ICIcon } from "@iconify/react";

import outlineScreenShare from "@iconify-icons/ic/outline-screen-share";
import outlineStopScreenShare from "@iconify/icons-vaadin/workplace";
import workplace from "@iconify/icons-vaadin/workplace";
import arrowLeft from "@iconify-icons/fa-solid/arrow-left";
import arrowRight from "@iconify-icons/fa-solid/arrow-right";
import googleMeet from "@iconify-icons/logos/google-meet";

import cursorHandGrabLine from "@iconify-icons/clarity/cursor-hand-grab-line";

import {
  CaretDownOutlined,
  CaretRightOutlined,
  CaretLeftOutlined,
  UserOutlined,
} from "@ant-design/icons";

import {
  mdiAccountMultiple,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiCogOutline,
  mdiCreditCardOutline,
  mdiMap,
  mdiMicrophone,
  mdiMicrophoneOff,
  mdiMonitorScreenshot,
  mdiOfficeBuildingMarker,
  mdiVideo,
  mdiVideoOff,
  mdiAccountPlus,
  mdiCheck,
  mdiChevronRight,
  mdiDelete,
  mdiMenu,
  mdiPlus,
  mdiMinus,
  mdiAccountGroup,
  mdiPin,
  mdiPinOff,
  mdiAccountOff,
  mdiCheckCircle,
  mdiSnowflake,
  mdiSnowflakeOff,
  mdiMusicNoteOff,
  mdiDoorClosed,
  mdiDoorClosedLock,
  mdiLock,
  mdiBell,
  mdiAccountArrowRight,
  mdiLinkPlus,
  mdiCalendarBlank,
  mdiContentCopy,
  mdiShare,
  mdiAlert,
  mdiAccountArrowLeft,
  mdiLink,
  mdiClose,
  mdiApps,
  mdiChevronDoubleUp,
  mdiMessageVideo,
  mdiPencil,
  mdiExitRun,
  mdiVolumeHigh,
  mdiVolumeOff,
  mdiClockOutline,
  mdiCalendarToday,
  mdiCalendarMonth,
  mdiCalendarImport,
  mdiViewGridPlus,
  mdiTune,
  mdiLogoutVariant,
  mdiArrowCollapse,
  mdiBackburger,
  mdiForwardburger,
  mdiDotsVertical,
  mdiFloorPlan,
  mdiInformation,
  mdiCancel,
  mdiForum,
  mdiWeb,
  mdiAccountCircle,
  mdiLoginVariant,
  mdiEyeOutline,
  mdiUpload,
  mdiArrowExpand,
  mdiUnfoldMoreHorizontal,
  mdiMapMarker,
  mdiPoll,
  mdiDotsHorizontal,
  mdiLoading,
  mdiArrowUpBoldCircleOutline,
  mdiVideoAccount,
  mdiHomeMapMarker,
  mdiWhatsapp,
} from "@mdi/js";

import fourOclock from "@iconify-icons/noto/four-oclock";
import noEntry from "@iconify-icons/noto/no-entry";
import forkAndKnife from "@iconify-icons/noto/fork-and-knife";
import hotBeverage from "@iconify-icons/noto/hot-beverage";
import greenCircle from "@iconify-icons/noto/green-circle";
import redCircle from "@iconify-icons/noto/red-circle";
import checkMark from "@iconify-icons/noto/check-mark";
import sortAlphabeticalAscending from "@iconify-icons/mdi/sort-alphabetical-ascending";
import sortAlphabeticalDescending from "@iconify-icons/mdi/sort-alphabetical-descending";
import briefcaseIcon from "@iconify-icons/noto/briefcase";
import musicalNotes from "@iconify-icons/noto/musical-notes";
import birthdayCake from "@iconify-icons/noto/birthday-cake";
import faceInClouds from "@iconify-icons/noto/face-in-clouds";
import eyesIcon from "@iconify-icons/noto/eyes";
import openMailboxWithRaisedFlag from "@iconify-icons/noto/open-mailbox-with-raised-flag";
import lockedWithKey from "@iconify-icons/noto/locked-with-key";
import partyPopper from "@iconify-icons/noto/party-popper";
import pencilCircle from "@iconify-icons/mdi/pencil-circle";
import openBook from "@iconify-icons/noto/open-book";
import clapperBoard from "@iconify-icons/noto/clapper-board";
import birthday from "@iconify-icons/noto/partying-face";
import publicMeeting from "@iconify-icons/noto/up-right-arrow";
import appointment from "@iconify-icons/noto/tear-off-calendar";
import lockedIcon from "@iconify-icons/noto/locked";
import doorIcon from "@iconify-icons/noto/door";
import flagForBrazil from "@iconify-icons/emojione-v1/flag-for-brazil";
import flagForFlagUsOutlyingIslands from "@iconify-icons/emojione-v1/flag-for-united-states";
import timerClock from "@iconify-icons/noto/timer-clock";
import crayonIcon from "@iconify-icons/noto/crayon";
import clubSuit from "@iconify-icons/noto/club-suit";
import detectiveIcon from "@iconify-icons/noto/detective";
import crossedSwords from "@iconify-icons/noto/crossed-swords";
import spiralNotepad from "@iconify-icons/noto/spiral-notepad";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import slackIcon from "@iconify-icons/grommet-icons/slack";

import addExternalCall from "../svgs/addExternalCall.svg";
import deleteExternalCall from "../svgs/deleteExternalCall.svg";

const icons = {
  close: [0, mdiClose],
  video: [0, mdiVideo],
  videoOff: [0, mdiVideoOff],
  edit: [0, mdiPencil],
  chevronDoubleLeft: [0, mdiChevronDoubleLeft],
  chevronDoubleRight: [0, mdiChevronDoubleRight],
  chevronRight: [0, mdiChevronRight],
  microphone: [0, mdiMicrophone],
  microphoneOff: [0, mdiMicrophoneOff],
  speaker: [0, mdiVolumeHigh],
  speakerOff: [0, mdiVolumeOff],
  expandScreen: [0, mdiMonitorScreenshot],
  map: [0, mdiMap],
  floorPlan: [0, mdiFloorPlan],
  calendar: [0, mdiCalendarBlank],
  copy: [0, mdiContentCopy],
  share: [0, mdiShare],
  kick: [0, mdiExitRun],
  loading: [0, mdiLoading],
  addMember: [0, mdiAccountPlus],
  check: [0, mdiCheck],
  delete: [0, mdiDelete],
  bell: [0, mdiBell],
  payment: [0, mdiCreditCardOutline],
  settings: [0, mdiCogOutline],
  info: [0, mdiInformation],
  office: [0, mdiOfficeBuildingMarker],
  users: [0, mdiAccountMultiple],
  menu: [0, mdiMenu],
  plus: [0, mdiPlus],
  participants: [0, mdiAccountGroup],
  lock: [0, mdiLock],
  pin: [0, mdiPin],
  closeDoor: [0, mdiDoorClosedLock],
  openDoor: [0, mdiDoorClosed],
  pinOff: [0, mdiPinOff],
  snow: [0, mdiSnowflake],
  alert: [0, mdiAlert],
  stats: [0, mdiPoll],
  linkPlus: [0, mdiLinkPlus],
  link: [0, mdiLink],
  googleMeet: [2, googleMeet],
  musicOff: [0, mdiMusicNoteOff],
  snowOff: [0, mdiSnowflakeOff],
  cancel: [0, mdiCancel],
  messageVideo: [0, mdiMessageVideo],
  uniqueEvent: [0, mdiCalendarToday],
  recurrentEvent: [0, mdiCalendarMonth],
  externalEvent: [0, mdiCalendarImport],
  inviteRoom: [0, mdiAccountArrowRight],
  invitedMember: [0, mdiAccountArrowLeft],
  collapse: [0, mdiArrowCollapse],
  expand: [0, mdiArrowExpand],
  chat: [0, mdiForum],
  whatsapp: [0, mdiWhatsapp],
  screenShare: [2, outlineScreenShare],
  language: [0, mdiWeb],
  screenShareOff: [2, outlineStopScreenShare],
  workplace: [2, workplace],
  clock: [0, mdiClockOutline],
  home: [0, mdiHomeMapMarker],
  floor: [0, mdiOfficeBuildingMarker],
  knock: [2, cursorHandGrabLine],
  caretDown: [1, CaretDownOutlined],
  arrowLeft: [2, arrowLeft],
  arrowRight: [2, arrowRight],
  caretRight: [1, CaretRightOutlined],
  caretLeft: [1, CaretLeftOutlined],
  ABSENT: [2, fourOclock],
  BUSY: [2, noEntry],
  LUNCH: [2, forkAndKnife],
  OFF: [0, mdiAccountOff],
  BREAK: [2, hotBeverage],
  ON: [0, mdiCheckCircle],
  greenCircle: [2, greenCircle],
  redCircle: [2, redCircle],
  checkMark: [2, checkMark],
  apps: [0, mdiApps],
  pencil: [0, mdiPencil],
  chevronDoubleUp: [0, mdiChevronDoubleUp],
  alphaDesc: [2, sortAlphabeticalDescending],
  alphaAsc: [2, sortAlphabeticalAscending],
  briefcase: [2, briefcaseIcon],
  musicalNotes: [2, musicalNotes],
  birthdayCake: [2, birthdayCake],
  minus: [0, mdiMinus],
  faceInClouds: [2, faceInClouds],
  eyesIcon: [2, eyesIcon],
  eye: [1, Visibility],
  eye_off: [1, VisibilityOff],
  user: [1, UserOutlined],
  mailbox: [2, openMailboxWithRaisedFlag],
  padlock: [2, lockedWithKey],
  popper: [2, partyPopper],
  pencilCircle: [2, pencilCircle],
  cake: [2, birthdayCake],
  openBook: [2, openBook],
  clapperBoard: [2, clapperBoard],
  publicMeeting: [2, publicMeeting],
  birthday: [2, birthday],
  appointment: [2, appointment],
  tune: [0, mdiTune],
  viewGridPlus: [0, mdiViewGridPlus],
  logout: [0, mdiLogoutVariant],
  slack: [2, slackIcon],
  backBurger: [0, mdiBackburger],
  forwardBurger: [0, mdiForwardburger],
  dotsVertical: [0, mdiDotsVertical],
  locked: [2, lockedIcon],
  brazil: [2, flagForBrazil],
  usa: [2, flagForFlagUsOutlyingIslands],
  accountCircle: [0, mdiAccountCircle],
  enterRoom: [0, mdiLoginVariant],
  eyeOutline: [0, mdiEyeOutline],
  upload: [0, mdiUpload],
  door: [2, doorIcon],
  unfoldMore: [0, mdiUnfoldMoreHorizontal],
  marker: [0, mdiMapMarker],
  upgrade: [0, mdiArrowUpBoldCircleOutline],
  crayon: [2, crayonIcon],
  dotsHorizontal: [0, mdiDotsHorizontal],
  timer: [2, timerClock],
  clubSuit: [2, clubSuit],
  detective: [2, detectiveIcon],
  battle: [2, crossedSwords],
  notepad: [2, spiralNotepad],
  addExternalCall: [3, addExternalCall],
  deleteExternalCall: [3, deleteExternalCall],
  videoAccount: [0, mdiVideoAccount],
};

const libraries = [
  (icon, props) => <MDI path={icon} {...props} />,
  (Icon, props) => <Icon {...props} />,
  (icon, props) => <ICIcon icon={icon} {...props} />,
  (icon, props) => <img src={icon} alt="svg-icon" {...props} />,
];

export default function Icon({ type, className = "", iProps = {}, ...props }) {
  return (
    <i className={"demy-icon".concat(" ").concat(className)} {...props}>
      {libraries[icons[type][0]](icons[type][1], iProps)}
    </i>
  );
}
