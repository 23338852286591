import React from "react";
import { Input, Form } from "antd";
import styled from "styled-components";

import Icon from "../Icon";

export const StyledSpan = styled.span`
  padding: 0;
  color: ${(props) => props.labelColor || "#373f66"};
  line-height: 1.5715;
  white-space: initial;
  font-style: normal;
  font-weight: ${(props) => props.labelWeight || "normal"};
`;

export function Label({
  labelClass,
  labelStyle,
  align,
  icon,
  iconClass,
  iconStyle,
  fontSize,
  textClass,
  textStyle,
  children,
  ...props
}) {
  return (
    <div
      className={
        labelClass ? labelClass : `d:f flx-d:r ${align || "j-c:c"} m-b:1`
      }
      style={{ ...labelStyle }}
    >
      {icon && (
        <span className="d:f a-i:s">
          <Icon type={icon} className={iconClass} style={{ ...iconStyle }} />
        </span>
      )}
      <StyledSpan
        className={textClass || `a-s:c ${fontSize || ""}`}
        style={
          textStyle ? textStyle : fontSize ? undefined : { fontSize: "15px" }
        }
      >
        {children}
      </StyledSpan>
    </div>
  );
}

export const FormItemStyled = styled(Form.Item)`
  font-weight: 600;
  font-size: 0.6em;
  margin-bottom: 15px;

  label {
    color: white;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

export function FormItem({
  label,
  name,
  rules,
  dependencies,
  style,
  prefix,
  initialValue,
  ...props
}) {
  return (
    <FormItemStyled
      label={label}
      name={name}
      rules={rules}
      dependencies={dependencies}
      initialValue={initialValue}
      style={
        style?.width
          ? { ...style, width: style.width }
          : { ...style, width: "100%" }
      }
      hasFeedback
    >
      <Input size="large" addonBefore={prefix} {...props} />
    </FormItemStyled>
  );
}
