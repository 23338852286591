import React from "react";
import { Radio } from "antd";
import Avatar from "avataaars";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import styled from "styled-components";
import PropTypes from "prop-types";

import PartSelector from "./PartSelector";
import { colors } from "./library";

const ColorSquare = styled.div`
  width: 100%;
  height: 100%;
`;
const StyledGroup = styled.div`
  display: flex;
  margin: 20px 0px 0px 10px;
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    span.ant-radio + * {
      padding-right: 0px;
    }
  }
`;
const SquareDiv = styled.div`
  width: 25px;
  height: 25px;
  margin: 10px 0px 0px 10px;
  border-radius: var(--base-radius);
  display: inline-block;
  cursor: pointer;
  .menu-item.active {
    border: 2px solid #373f66;
  }
  .menu-item {
    height: 100%;
    padding: 2px;
  }
`;

export default function AvatarEditorInput({ value, onChange }) {
  const { t } = useTranslation();

  const avatarValues = value ? qs.parse(value.split("?")[1]) : {};

  const handleChange = (property, value) => {
    const newValues = { ...avatarValues };
    newValues[property] = value;
    onChange(`https://avataaars.io/?${qs.stringify(newValues)}`);
  };

  return (
    <div className="d:f">
      <div className="t-a:c">
        <h1 className={`m-b:2`}>{t("my-avatar.label")}</h1>
        <Avatar
          style={{
            width: 150,
            height: 150,
            borderRadius: 85,
            backgroundColor: avatarValues.avatarBackground,
          }}
          avatarStyle={"Transparent"}
          {...avatarValues}
        />

        <Radio.Group
          classname="m-t:1 d:f"
          onChange={(e) => handleChange("avatarBackground", e.target.value)}
          value={
            avatarValues.avatarBackground?.length
              ? Object.keys(colors.avatarColors)[0]
              : avatarValues.avatarBackground
          }
        >
          <StyledGroup className="m-t:1 d:f t-a:c a-i:c">
            <Radio value={Object.keys(colors.avatarColors)[0]}>
              {t("circle.label")}
            </Radio>
            <Radio value="">{t("transparent.label")}</Radio>
          </StyledGroup>
        </Radio.Group>

        <div className="m-t:3">
          {Object.keys(colors.avatarColors).map((color) => (
            <SquareDiv
              onClick={() => handleChange("avatarBackground", color)}
              key={color}
            >
              <div
                className={`menu-item ${
                  avatarValues.avatarBackground === color ? "active" : ""
                }`}
              >
                <ColorSquare style={{ backgroundColor: `${color}` }} />
              </div>
            </SquareDiv>
          ))}
        </div>
      </div>
      <div className="flx-g:1 o:h">
        <PartSelector onChange={handleChange} values={avatarValues} />
      </div>
    </div>
  );
}

AvatarEditorInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};
