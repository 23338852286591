import React from "react";
import { StyledCard } from "../Authentication";

export default function OnboardingCard({ style, ...props }) {
  return (
    <StyledCard
      style={{
        display: "block",
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        ...style,
      }}
      {...props}
    />
  );
}
