import React from "react";
import { Result } from "antd";

export default function MaintenancePage() {
  return (
    <div className="flx-g:1 d:f flx-d:c pos:r">
      <div className="t-a:c top:3 pos:a w:full">
        <img src="/logo-azul.png" width="140px" alt="deskmy logo" />
      </div>
      <div className="m-y:a">
        <Result
          status="500"
          title="Desculpe-nos, o site está em manutação"
          subTitle="Aguarde alguns instantes e atualize a página novamente"
        />
      </div>
    </div>
  );
}
