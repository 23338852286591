import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Internationalization from "./contexts/internationalization";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    ignoreErrors: [
      /NotAllowedError: play().*/,
      /Cannot read property 'play' of null.*/,
      /TypeError: Cannot read property 'play' of null.*/,
      /NotAllowedError: The play method is not allowed.*/,
      /Error: request timeout/,
      /.*ResizeObserver.*/,
      /.*Cannot read property 'canProduce' of null.*/,
    ],
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

  document.domain = "deskmy.com";
}

ReactDOM.render(
  <React.StrictMode>
    <Internationalization>
      <App />
    </Internationalization>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
