import React, { lazy } from "react";
import { Route, useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import withPrivateRoute from "../components/withPrivateRoute";
import { AuthProvider } from "../contexts/auth";
import { withLocaleRoute } from "../contexts/internationalization";
import useUrlHash from "../hooks/useUrlHash";

import Switcher from "./Switcher";
import { useEffect } from "react";

const InvitedLogin = lazy(() => import("../pages/InvitedLogin"));
const InvitedCall = lazy(() => import("../pages/InvitedCall"));

export const routes = [
  {
    path: "/:locale(en|pt)?/meet/:hash/login",
    component: withLocaleRoute(InvitedLogin),
  },
  {
    path: "/meet/:hash",
    component: withPrivateRoute(InvitedCall),
  },
];

function InvitedWrapper() {
  const hash = useUrlHash();
  const history = useHistory();

  useEffect(() => {
    const cookieHash = Cookies.get("callHash");
    Cookies.set("callHash", hash);
    console.log(cookieHash, hash);
    if (cookieHash !== hash) history.push(`/meet/${hash}/login`);
  }, [hash]);

  return (
    <AuthProvider
      isInvitedUser
      loginRedirect={`/meet/${hash}`}
      loginUrl={`/meet/${hash}/login`}
    >
      <Switcher routes={routes} />
    </AuthProvider>
  );
}

const invitedRoutes = routes.map((route) => (
  <Route key={route.path} {...route} component={InvitedWrapper} />
));

export default invitedRoutes;
