import { Form, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import api, { get } from "../../api/api";
import useAuth from "../../contexts/auth";
import OnboardingCard from "./OnboardingCard";
import { Label, FormItem } from "../Authentication/Form";
import { requiredRule } from "../../utils/forms";

export default function RoomsStep({ onSubmit, footer }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { data = [] } = useSWR("/rooms/", get, {
    revalidateOnFocus: false,
  });

  const handleSubmit = (rooms) =>
    onSubmit(async () => {
      const requests = [];
      for (const room of Object.entries(rooms)) {
        requests.push(api.patch(`/rooms/${room[0]}/`, { name: room[1] }));
      }
      return Promise.all(requests);
    });

  return (
    <Form onFinish={handleSubmit}>
      <div className="c:bright-blue f-f:heebo f-w:400 f:2 m-t:1 m-b:4">
        {t("Onboarding.step-1.title-1")},
        <b className="c:yellow">{` ${user?.first_name} ${user?.last_name}`}</b>
        .
        <br />
        {t("Onboarding.step-1.title-2", {
          var: user?.company.name ? `${user?.company.name}` : "sua empresa",
        })}
        .
        <br />
        {t("Onboarding.step-1.title-3")}
        <br />
      </div>
      <OnboardingCard style={{ maxWidth: 450 }}>
        {data ? (
          <div>
            <Label align={"t-a:c j-c:c"} fontSize={"f:2"}>
              {t("RenameRooms.title")}
            </Label>
            {data
              .filter(({ base_room: { type } }) => type === "SQUAD")
              .map((room) => (
                <FormItem
                  key={room.id}
                  style={{ marginBottom: "12px" }}
                  name={room.id}
                  initialValue={`${room.name}`}
                  rules={[requiredRule]}
                  maxLength={50}
                  placeholder={t("team-name.label")}
                />
              ))}
          </div>
        ) : (
          <Spin size="large" />
        )}
        {footer}
      </OnboardingCard>
    </Form>
  );
}
