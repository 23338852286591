import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import api from "../../api/api";
import { BackgroundDiv, CenterDiv } from "../Authentication";
import DeskLogo from "../../svgs/logoB.svg";
import useAuth from "../../contexts/auth";
import Progress from "../Progress";
import Loading from "../Loader";
import RoomsStep from "./RoomsStep";
import AvatarStep from "./AvatarStep";
import InvitationStep from "./InvitationStep";
import Button from "../Button";
import { Space } from "antd";

export function Onboarding() {
  const { t } = useTranslation();
  const { user, update } = useAuth();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [callbacks, setCallbacks] = useState({});

  const isAdminOrManager = ["MANAGER", "ADMIN"].includes(user.access_type, 0);

  const steps = [
    isAdminOrManager && RoomsStep,
    AvatarStep,
    InvitationStep,
  ].filter(Boolean);

  const handleFinish = useCallback(
    async (promises) => {
      try {
        setLoading(true);
        for (const fun of Object.values(promises)) {
          await fun();
        }
      } catch (error) {}
      try {
        await api.post("/users/update_step/", {
          id: user.id,
          onboarding_completed: true,
        });
        update();
      } catch {}
      setLoading(false);
    },
    [update, user.id]
  );

  const handleContinue = (callback) => {
    const newCallbacks = { ...callbacks };
    if (callback) {
      newCallbacks[step] = callback;
      setCallbacks(newCallbacks);
    }
    if (step + 1 < steps.length) setStep(step + 1);
    else handleFinish(newCallbacks);
  };

  const footer = (
    <div className="t-a:r">
      <Space>
        <Button
          className="f-w:500"
          type="text"
          onClick={() => handleContinue()}
          loading={loading}
        >
          {t("skip.label")}
        </Button>
        <Button
          className="f-w:500"
          type="yellow-shadowed"
          loading={loading}
          htmlType="submit"
        >
          {t("next.label")}
        </Button>
      </Space>
    </div>
  );

  const Component = steps[step];

  const canRender = !loading;

  return (
    <BackgroundDiv
      bgImg="img/escritorio-hall-blur5px.png"
      bgTint="rgba(15, 24, 65, 0.95)"
    >
      <CenterDiv className="m-t:5 m-b:0" style={{ maxWidth: "65vw" }}>
        <div>
          <img src={DeskLogo} alt="Deskmy Logo" style={{ width: "120px" }} />
        </div>

        {canRender ? (
          <Component footer={footer} onSubmit={handleContinue} />
        ) : (
          <Loading />
        )}
        {canRender && (
          <Progress count={steps.length} onChange={setStep} value={step} />
        )}
      </CenterDiv>
    </BackgroundDiv>
  );
}

export default function OnboardingWrapper({ children }) {
  const { user } = useAuth();

  return !user || user.onboarding_completed ? children : <Onboarding />;
}
