import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import jwt from "jwt-decode";
import i18next from "i18next";
import { configureScope } from "@sentry/react";
import { message } from "antd";
import { useRouteMatch } from "react-router-dom";
import Cookies from "js-cookie";

import usePrevious from "../hooks/usePrevious";
import ls from "../utils/LocalStorage";
import api from "../api/api";

export const AuthContext = createContext({});

export const AuthProvider = ({
  children,
  loginRedirect = "/",
  loginUrl,
  isInvitedUser = false,
}) => {
  const { params: routeParams } = useRouteMatch();
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [access, setAccess] = useState();

  const prevUser = usePrevious(user);
  // Variaveis dependentes do tipo de usuario
  const refreshLSKey = isInvitedUser ? "invitedRefresh" : "refresh";
  const userLSKey = isInvitedUser ? "invitedUser" : "user";
  const shouldFetchUsers = true;
  const shouldLinkTawkto = !isInvitedUser;
  loginUrl = loginUrl || window.location.origin.concat("/login");

  const saveUser = useCallback(
    (user) => {
      setUser(user);
      ls.set(userLSKey, user);
    },
    [userLSKey]
  );

  const logout = useCallback(
    (redirectTo) => {
      ls.remove(userLSKey);
      ls.remove(refreshLSKey);
      setUser(null);
      window.location.href = redirectTo || loginUrl;
    },
    [loginUrl, refreshLSKey, userLSKey]
  );
  const silentLogout = useCallback(() => {
    ls.remove(userLSKey);
    ls.remove(refreshLSKey);
  }, [userLSKey, refreshLSKey]);

  const routeParamsLocale = routeParams.locale;
  const fetchUser = useCallback(async () => {
    try {
      const decoded_token = jwt(ls.get(refreshLSKey));
      let { data: user } = await api.get(
        "/users/" + decoded_token.user_id + "/"
      );
      configureScope((scope) =>
        scope.setUser({ id: user.id, email: user.email })
      );
      if (
        i18next.language !== user.language.toLowerCase() &&
        !routeParamsLocale
      )
        i18next.changeLanguage(user.language.toLowerCase());
      // if (
      //   typeof document.Tawk_API != "undefined" &&
      //   document.Tawk_API?.setAttributes &&
      //   shouldLinkTawkto
      // ) {
      //   console.log("Tawk initiated");
      //   document.Tawk_API.setAttributes({
      //     name: user.name,
      //     email: user.email,
      //     id: user.id,
      //   });
      // }
      saveUser(user);
    } catch {
      saveUser(null);
    }
    setLoading(false);
  }, [refreshLSKey, saveUser, routeParamsLocale]);

  const saveToken = useCallback(
    (access, refresh) => {
      api.defaults.headers.common["Authorization"] = "Bearer " + access;
      Cookies.set("access", access, {
        domain:
          process.env.NODE_ENV === "production"
            ? ".deskmy.com"
            : `.${document.domain}`,
      });
      ls.set(refreshLSKey, refresh);
    },
    [refreshLSKey]
  );

  const setInterceptor = useCallback(
    (refresh) => {
      api.interceptors.response.use(null, (e) => {
        if (
          e.response?.status === 401 &&
          e.response?.data?.code === "token_not_valid"
        ) {
          return api
            .post("/auth/token/refresh/", { refresh })
            .then(({ data }) => {
              e.config.headers.Authorization = "Bearer " + data.access;
              saveToken(data.access, refresh);
              return api.request(e.config);
            })
            .catch(() => (window.location.href = loginUrl));
        }
        return Promise.reject(e);
      });
    },
    [loginUrl, saveToken]
  );

  const fetchMembers = useCallback(async () => {
    try {
      const { data } = await api.get("/users/");
      setUsers(data);
    } catch {}
  }, []);

  const fetchAuthorization = useCallback(async () => {
    const refresh = ls.get(refreshLSKey);
    if (refresh)
      try {
        let { data } = await api.post("/auth/token/refresh/", { refresh });
        saveToken(data.access, refresh);
        setAccess(data.access);
        setInterceptor(refresh);
        const fromLocalStorage = ls.get(userLSKey);
        if (fromLocalStorage) setUser(fromLocalStorage);
      } catch (e) {
      } finally {
        fetchUser();
      }
    else setLoading(false);
  }, [fetchUser, refreshLSKey, saveToken, setInterceptor, userLSKey]);

  const shouldFetchAuthorization = !prevUser;
  useEffect(() => {
    if (shouldFetchAuthorization) fetchAuthorization();
  }, [shouldFetchAuthorization, fetchAuthorization]);

  useEffect(() => {
    if (!prevUser && user && shouldFetchUsers) fetchMembers();
  }, [prevUser, user, shouldFetchUsers, fetchMembers]);

  const login = async (username, password, preventRedirect = false) => {
    setLoading(true);
    try {
      const {
        data: { access, refresh },
      } = await api.post("/auth/token/", { username, password });
      if (access) {
        setAccess(access);
        setInterceptor(refresh);
        saveToken(access, refresh);
        await fetchUser();
        if (!preventRedirect) window.location.href = loginRedirect;
      }
    } finally {
      setLoading(false);
    }
  };

  const tokenLogin = useCallback(
    async (token) => {
      setLoading(true);
      try {
        const {
          data: { access, refresh },
        } = await api.post("/auth/get_token_refresh_by_user_token/", { token });
        if (access) {
          setAccess(access);
          setInterceptor(refresh);
          saveToken(access, refresh);
          await fetchUser();
          window.location.href = loginRedirect;
        }
      } catch {
        message.error("Link expirado! Tente novamente");
      } finally {
        setLoading(false);
      }
    },
    [fetchUser, loginRedirect, saveToken, setInterceptor]
  );

  const switchLanguage = async () => {
    const { data: res } = await api.patch(`/users/${user.id}/`, {
      language: user.language === "pt" ? "en" : "pt",
    });
    saveUser(res);
    i18next.changeLanguage(res.language.toLowerCase());
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        users,
        login,
        tokenLogin,
        loading,
        logout,
        update: fetchUser,
        update_users: fetchMembers,
        access,
        mutate: (newInfo) => saveUser({ ...user, ...newInfo }),
        loginUrl,
        updateMembers: fetchMembers,
        silentLogout,
        switchLanguage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export function withAuth(Component) {
  return (props) => {
    const auth = useAuth();
    return <Component {...props} {...auth} />;
  };
}
