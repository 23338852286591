export const data = {
  topType: [
    { name: "LongHairBigHair", path: "Big Hair.svg", type: "hairColor" },
    { name: "LongHairBob", path: "Bob.svg", type: "hairColor" },
    { name: "LongHairBun", path: "Bun.svg", type: "hairColor" },
    { name: "LongHairCurly", path: "Curly.svg", type: "hairColor" },
    { name: "LongHairCurvy", path: "Curvy.svg", type: "hairColor" },
    { name: "LongHairDreads", path: "Dreads.svg", type: "hairColor" },
    { name: "LongHairFrida", path: "Frida.svg" },
    { name: "LongHairFroBand", path: "Fro n Band.svg", type: "hairColor" },
    {
      name: "LongHairNotTooLong",
      path: "Long but not too long.svg",
      type: "hairColor",
    },
    { name: "LongHairMiaWallace", path: "Mia Wallace.svg", type: "hairColor" },
    { name: "LongHairShavedSides", path: "Shaved Sides.svg" },
    { name: "LongHairStraight", path: "Straight.svg", type: "hairColor" },
    {
      name: "LongHairStraightStrand",
      path: "Straight n Strand.svg",
      type: "hairColor",
    },
    { name: "LongHairStraight2", path: "Straight-1.svg", type: "hairColor" },
    { name: "ShortHairDreads01", path: "Dreads 01.svg", type: "hairColor" },
    { name: "ShortHairDreads02", path: "Dreads 02.svg", type: "hairColor" },
    { name: "ShortHairFrizzle", path: "Frizzle.svg", type: "hairColor" },
    {
      name: "ShortHairShaggyMullet",
      path: "Shaggy Mullet.svg",
      type: "hairColor",
    },
    // { name: 'ShortHairShaggy', path: 'Shaggy.svg', type: 'hairColor' },
    { name: "ShortHairShortFlat", path: "Short Flat.svg", type: "hairColor" },
    { name: "ShortHairShortRound", path: "Short Round.svg", type: "hairColor" },
    { name: "ShortHairShortWaved", path: "Short Waved.svg", type: "hairColor" },
    { name: "ShortHairSides", path: "Sides.svg", type: "hairColor" },
    { name: "ShortHairTheCaesar", path: "The Caesar.svg", type: "hairColor" },
    {
      name: "ShortHairTheCaesarSidePart",
      path: "The Caesar n Side Part.svg",
      type: "hairColor",
    },
    { name: "WinterHat1", path: "Winter Hat 1.svg", type: "hatColor" },
    { name: "WinterHat2", path: "Winter Hat 2.svg", type: "hatColor" },
    { name: "WinterHat3", path: "Winter Hat 3.svg", type: "hatColor" },
    { name: "WinterHat4", path: "Winter Hat 4.svg", type: "hatColor" },
    { name: "NoHair", path: "No Hair.svg", empty: true },
    { name: "Eyepatch", path: "Eyepatch.svg" },
    { name: "Turban", path: "Turban.svg", type: "hatColor" },
    { name: "Hat", path: "Hat.svg" },
    { name: "Hijab", path: "Hijab.svg", type: "hatColor" },
  ],
  accessoriesType: [
    { name: "Blank", path: "Blank.svg" },
    { name: "Kurt", path: "Kurt.svg" },
    { name: "Prescription01", path: "Prescription 01.svg" },
    { name: "Prescription02", path: "Prescription 02.svg" },
    { name: "Sunglasses", path: "Sunglasses.svg" },
    { name: "Round", path: "Round.svg" },
    { name: "Wayfarers", path: "Wayfarers.svg" },
  ],
  skinColor: [
    { name: "Tanned", path: "🍊 Tanned.svg" },
    { name: "Yellow", path: "👶 00 Yellow.svg" },
    { name: "Pale", path: "👶🏻 05 Pale.svg" },
    { name: "Light", path: "👶🏼 04 Light.svg" },
    { name: "Brown", path: "👶🏽 03 Brown.svg" },
    { name: "DarkBrown", path: "👶🏾 02 Dark Brown.svg" },
    { name: "Black", path: "👶🏿 01 Black.svg" },
  ],
  mouthType: [
    { name: "Concerned", path: "Concerned.svg" },
    { name: "Default", path: "Default.svg" },
    { name: "Disbelief", path: "Disbelief.svg" },
    { name: "Eating", path: "Eating.svg" },
    { name: "Grimace", path: "Grimace.svg" },
    { name: "Sad", path: "Sad.svg" },
    { name: "ScreamOpen", path: "Scream Open.svg" },
    { name: "Serious", path: "Serious.svg" },
    { name: "Smile", path: "Smile.svg" },
    { name: "Tongue", path: "Tongue.svg" },
    { name: "Twinkle", path: "Twinkle.svg" },
    { name: "Vomit", path: "Vomit.svg" },
  ],
  facialHairType: [
    { name: "Blank", path: "Blank.svg" },
    { name: "BeardLight", path: "Beard Light.svg", type: "facialHairColor" },
    // {
    //   name: 'BeardMagestic',
    //   path: 'Beard Magestic.svg',
    //   type: 'facialHairColor',
    // },
    { name: "BeardMedium", path: "Beard Medium.svg", type: "facialHairColor" },
    {
      name: "MoustacheFancy",
      path: "Moustache Fancy.svg",
      type: "facialHairColor",
    },
    {
      name: "MoustacheMagnum",
      path: "Moustache Magnum.svg",
      type: "facialHairColor",
    },
  ],
  eyeType: [
    { name: "Close", path: "Closed 😌.svg" },
    { name: "Cry", path: "Cry 😢.svg" },
    { name: "Default", path: "Default 😀.svg" },
    { name: "EyeRoll", path: "Eye Roll.svg" },
    { name: "Happy", path: "Happy 😁.svg" },
    { name: "Hearts", path: "Hearts 😍.svg" },
    { name: "Side", path: "Side 😒.svg" },
    { name: "Squint", path: "Squint.svg" },
    { name: "Surprised", path: "Surprised 😳.svg" },
    { name: "Wink", path: "Wink.svg" },
    { name: "WinkWacky", path: "Wink Wacky 😜.svg" },
    { name: "Dizzy", path: "X Dizzy 😵.svg" },
  ],
  eyebrowType: [
    { name: "AngryNatural", path: "Angry Natural.svg" },
    { name: "Angry", path: "Angry.svg" },
    { name: "DefaultNatural", path: "Default Natural.svg" },
    { name: "Default", path: "Default.svg" },
    { name: "FlatNatural", path: "Flat Natural.svg" },
    // { name: 'FrownNatural', path: 'Frown Natural.svg' },
    { name: "SadConcerned", path: "Sad Concerned.svg" },
    { name: "SadConcernedNatural", path: "Sad Concerned Natural.svg" },
    { name: "UnibrowNatural", path: "Unibrow Natural.svg" },
    { name: "UpDownNatural", path: "Up Down Natural.svg" },
    { name: "UpDown", path: "Up Down.svg" },
    { name: "RaisedExcited", path: "Raised Excited.svg" },
    { name: "RaisedExcitedNatural", path: "Raised Excited Natural.svg" },
  ],
  clotheType: [
    { name: "BlazerShirt", path: "Blazer n Shirt.svg" },
    { name: "BlazerSweater", path: "Blazer n Sweater.svg" },
    {
      name: "CollarSweater",
      path: "Collar n Sweater.svg",
      type: "clotheColor",
    },
    { name: "GraphicShirt", path: "Graphic Shirt.svg", type: "clotheColor" },
    { name: "Hoodie", path: "Hoodie.svg", type: "clotheColor" },
    { name: "Overall", path: "Overall.svg", type: "clotheColor" },
    { name: "ShirtCrewNeck", path: "Shirt Crew Neck.svg", type: "clotheColor" },
    {
      name: "ShirtScoopNeck",
      path: "Shirt Scoop Neck.svg",
      type: "clotheColor",
    },
    { name: "ShirtVNeck", path: "Shirt V Neck.svg", type: "clotheColor" },
  ],
};

export const colors = {
  hairColor: {
    "#a55728": "Auburn",
    "#2c1b18": "Black",
    "#b58143": "Blonde",
    "#d6b370": "BlondeGolden",
    "#724133": "Brown",
    "#4a312c": "BrownDark",
    "#f59797": "PastelPink",
    "#ecdcbf": "Platinum",
    "#c93305": "Red",
    "#e8e1e1": "SilverGray",
  },
  avatarColors: {
    "#262E33": "Black",
    "#65C9FF": "Blue01",
    "#5199E4": "Blue02",
    "#25557C": "Blue03",
    "#E6E6E6": "Gray01",
    "#929598": "Gray02",
    "#3C4F5C": "Heather",
    "#B1E2FF": "PastelBlue",
    "#A7FFC4": "PastelGreen",
    "#FFDEB5": "PastelOrange",
    "#FFAFB9": "PastelRed",
    "#FFFFB1": "PastelYellow",
    "#FF488E": "Pink",
    "#FF5C5C": "Red",
    "#ffffff": "White",
  },
  clotheColor: {
    "#262E33": "Black",
    "#65C9FF": "Blue01",
    "#5199E4": "Blue02",
    "#25557C": "Blue03",
    "#E6E6E6": "Gray01",
    "#929598": "Gray02",
    "#3C4F5C": "Heather",
    "#B1E2FF": "PastelBlue",
    "#A7FFC4": "PastelGreen",
    "#FFDEB5": "PastelOrange",
    "#FFAFB9": "PastelRed",
    "#FFFFB1": "PastelYellow",
    "#FF488E": "Pink",
    "#FF5C5C": "Red",
    "#ffffff": "White",
  },
  hatColor: {
    "#262E33": "Black",
    "#65C9FF": "Blue01",
    "#5199E4": "Blue02",
    "#25557C": "Blue03",
    "#E6E6E6": "Gray01",
    "#929598": "Gray02",
    "#3C4F5C": "Heather",
    "#B1E2FF": "PastelBlue",
    "#A7FFC4": "PastelGreen",
    "#FFDEB5": "PastelOrange",
    "#FFAFB9": "PastelRed",
    "#FFFFB1": "PastelYellow",
    "#FF488E": "Pink",
    "#FF5C5C": "Red",
    "#ffffff": "White",
  },
  facialHairColor: {
    "#a55728": "Auburn",
    "#2c1b18": "Black",
    "#b58143": "Blonde",
    "#d6b370": "BlondeGolden",
    "#724133": "Brown",
    "#4a312c": "BrownDark",
    "#ecdcbf": "Platinum",
    "#c93305": "Red",
  },
};
