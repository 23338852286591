import React from "react";
import { Result, Space } from "antd";
import { withTranslation } from "react-i18next";
import { captureException, showReportDialog, withScope } from "@sentry/react";

import Button from "./Button";
import { AuthContext } from "../contexts/auth";

const sentryOptions = {
  title: "Envie seu feedback sobre o erro",
  subtitle: "Nosso time já foi notificado sobre a falha. ",
  subtitle2:
    "Para que tenhamos um melhor entendimento, por favor descreva o que aconteceu.",
  labelComments: "O que aconteceu?",
  labelClose: "Fechar",
  labelSubmit: "Enviar",
  errrorGeneric:
    "Um erro desconhecido ocorreu ao enviar seu feedback. Por favor, tente novamente",
  errorFormEntry:
    "Algumas entradas estão erradas. Corriga os erros e tente novamente",
  successMessage: "Seu feedback froi enviado. Obrigado!",
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static contextType = AuthContext;

  static getDerivedStateFromError(error) {
    // Atualiza o state para que a próxima renderização mostre a UI alternativa.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error);
    withScope((scope) => {
      scope.setExtras(info);

      const eventId = captureException(error);
      this.setState({ eventId });
    });
    try {
      if (error.toString().indexOf("ChunkLoadError") > -1) {
        console.log("[ChunkLoadError] Reloading due to error");
        document.location.reload();
      }
    } catch {}
  }

  render() {
    const { eventId } = this.state;

    if (this.state.hasError) {
      return (
        <div className="p-t:2 t-a:c">
          <img src="/logo-azul.png" alt="deskmy logo" />
          <Result
            status="500"
            title="500"
            subTitle={this.props.t("ErrorBoundary.subTitle")}
            extra={
              <div>
                <p>{this.props.t("ErrorBoudary.text")}</p>
                <Space>
                  {eventId && (
                    <Button
                      onClick={() =>
                        showReportDialog({
                          eventId,
                          lang: this.props.language,
                          user: {
                            name: this.context?.user?.name,
                            email: this.context?.user?.email,
                          },
                          ...(this.props.language === "pt"
                            ? sentryOptions
                            : {}),
                        })
                      }
                    >
                      {this.props.t("report-error.label")}
                    </Button>
                  )}
                  <Button
                    type="primary"
                    onClick={() => window.location.reload()}
                  >
                    {this.props.t("ErrorBoudary.button")}
                  </Button>
                </Space>
              </div>
            }
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
