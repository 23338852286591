import React, { useEffect } from "react";
import useAuth from "../contexts/auth";

// export function withAwaitAuth(Route) {
//     return ({ ...props }) => {
//       const { loading } = useAuth();

//       if (!loading) return <Route {...props} />;
//       return null;
//     };
//   }

export default function withRedirectIfAuth({ Route, redirectToUrl }) {
  return ({ ...props }) => {
    const { isAuthenticated, loading } = useAuth();
    useEffect(() => {
      if (!loading && isAuthenticated) window.location.replace(redirectToUrl);
    }, [loading, isAuthenticated]);

    if (!loading && !isAuthenticated) return <Route {...props} />;
    return null;
  };
}
