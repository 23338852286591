import React, { createContext, useContext, useEffect } from "react";
import Cookies from "js-cookie";

const SystemNotificationContext = createContext();

export const useSystemNotification = () => {
  return useContext(SystemNotificationContext);
};

export default function SystemNotificationProvider({ children }) {
  const askPermissions = async () => {
    if (!window.Notification) {
      console.log("Esse browser não suporta notificações desktop");
    } else {
      // Pede ao usuário para utilizar a Notificação Desktop
      await Notification.requestPermission();
    }
  };
  useEffect(() => {
    const permissionStatus = Cookies.get("notficationPermissions");
    if (permissionStatus === undefined) askPermissions();
  }, []);
  const notify = (...props) => {
    if (Notification.permission === "granted") new Notification(...props);
  };
  return (
    <SystemNotificationContext.Provider value={{ askPermissions, notify }}>
      {children}
    </SystemNotificationContext.Provider>
  );
}
