import Axios from "axios";

const api = Axios.create({
  // baseURL: 'http://127.0.0.1:8000/',
  baseURL:
    (process.env.REACT_APP_USE_SSL ? "https://" : "http://") +
    process.env.REACT_APP_BACKEND_URL,
  // xsrfCookieName: "csrftoken",
  // xsrfHeaderName: "X-CSRFToken",
  // withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "User-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

export const get = (url, options) =>
  api.get(url, options).then(({ data }) => data);

export default api;
