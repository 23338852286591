import { Avatar, Badge, Form, message, Space } from "antd";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Icon from "../Icon";
import UserAvatar from "../UserAvatar";
import speaking from "../../svgs/speaking.svg";
import useAuth from "../../contexts/auth";
import api from "../../api/api";
import OnboardingCard from "./OnboardingCard";
import { Label, FormItem } from "../Authentication/Form";
import Button from "../Button";
import { emailRule } from "../../utils/forms";

export default function InvitationStep({ onSubmit, footer }) {
  const { user } = useAuth();
  const { t } = useTranslation();

  const isAdminOrManager = ["MANAGER", "ADMIN"].includes(user.access_type, 0);

  const handleSubmit = (values) =>
    onSubmit(async () => {
      try {
        if (values.invite0 !== undefined) {
          await api.post("/users/invitations/", {
            email: values.invite0,
            access_type: "MEMBER",
          });
        }
        if (values.name !== undefined) {
          const filteredEmails = values.names.filter(
            (item) => item !== undefined
          );
          if (filteredEmails.length > 0) {
            for (let index in filteredEmails) {
              await api.post("/users/invitations/", {
                email: filteredEmails[index],
                access_type: "MEMBER",
              });
            }
          }
        }
      } catch (e) {
        const data = e?.response?.data;

        if (typeof data === "string") message.error(data);
        else if (data?.email) message.error(e.response.data.email[0]);
        else message.error(t("feedback.generic.error"));

        throw e;
      }
    });

  return (
    <Form onFinish={handleSubmit}>
      <div>
        <div
          className={`c:bright-blue f-f:heebo f-w:400 f:2 m-t:1 ${
            !isAdminOrManager ? "m-b:10" : "m-b:4"
          }`}
        >
          <Trans i18nKey="Onboarding.step-3.title">
            Agora é só abrir o microfone e começar a falar nas salas. vamos
            trazer de volta a colaboração de estar no escritório,
            <b className="c:yellow"> a energia de trabalhar juntos</b> e a
            espontaneidade das conversas no intervalo do café.
          </Trans>
        </div>
        <div className={!isAdminOrManager ? "m-b:0" : "m-b:2"}>
          <Avataaars user={user} />
        </div>
      </div>
      <OnboardingCard style={{ maxWidth: 500 }}>
        <Label align={"t-a:c j-c:c"} fontSize="f:2">
          {t("InviteMembers.title")}
        </Label>
        <Label align="t-a:l">E-MAIL</Label>

        <FormItem
          name="invite0"
          placeholder={t("InviteMembers.email.placeholder")}
          rules={[emailRule]}
          maxLength={150}
        />

        <Form.List name="names">
          {(fields, { add }) => (
            <div>
              {fields.map((field) => (
                <Form.Item key={field.key} style={{ marginBottom: "0px" }}>
                  <div className="d:f flx-d:r">
                    <FormItem
                      {...field}
                      rules={[emailRule]}
                      placeholder={t("InviteMembers.email.placeholder")}
                      maxLength={150}
                    />
                  </div>
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="register"
                  onClick={() => add()}
                  style={{
                    textTransform: "none",
                    width: "100%",
                  }}
                >
                  <Label
                    icon="plus"
                    iconStyle={{ color: "#373f66" }}
                    fontSize={"f:1"}
                    align="t-a:l"
                  >
                    {t("add-more-contacts.label")}
                  </Label>
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>
        {footer}
      </OnboardingCard>
    </Form>
  );
}

function Avataaars({ user }) {
  const avatars = [
    {
      option:
        "&facialHairColor=&topType=ShortHairTheCaesar&accessoriesType=&hairColor=BrownDark&hatColor=&facialHairType=&clotheType=GraphicShirt&clotheColor=Red&eyeType=WinkWacky&graphicType=Pizza&eyebrowType=&mouthType=&skinColor=Black&avatarBackground=",
      color: "#65C9FF",
    },
    {
      isSpeaking: true,
      option:
        "&topType=LongHairCurvy&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=ShirtCrewNeck&clotheColor=Red&eyeType=Side&eyebrowType=Default&mouthType=Eating&skinColor=Yellow",
      color: "#FFAFB9",
    },
    {
      isSpeaking: true,
      customRender: <UserAvatar user={user} avatarSize={60} />,
    },
    {
      option:
        "&topType=LongHairBun&accessoriesType=Blank&hairColor=Blonde&facialHairType=BeardLight&facialHairColor=Blonde&clotheType=ShirtCrewNeck&clotheColor=White&eyeType=Squint&eyebrowType=Default&mouthType=Smile&skinColor=Light",
      color: "#25557C",
    },
    {
      option:
        "&topType=LongHairFroBand&accessoriesType=Prescription02&hairColor=Red&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=Default&mouthType=Twinkle&skinColor=Brown",
      color: "#E6E6E6",
    },
  ];

  return (
    <Space size="large">
      {avatars.map(({ option, color, isSpeaking, customRender }) => (
        <Badge
          count={
            isSpeaking ? (
              <img src={speaking} alt="Deskmy Logo" style={{ width: "40px" }} />
            ) : (
              <Icon className="f:3 c:yellow" type="microphoneOff" />
            )
          }
        >
          {customRender || (
            <Avatar
              src={`https://avataaars.io/?avatarStyle="Transparent"${option}`}
              size={60}
              style={{ backgroundColor: color }}
            />
          )}
        </Badge>
      ))}
    </Space>
  );
}
