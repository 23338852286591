import React from "react";
import styled from "styled-components";

import { data, colors } from "./library";

// Scroll horizontal com setas
export default function Palette({ values, type, onChange }) {
  let valueColors;
  const colorType = data[type].find(({ name }) => name === values[type])?.type;
  if (colorType) valueColors = colors[colorType];
  if (!valueColors) return false;

  return (
    <div className="t-a:c">
      {Object.keys(valueColors).map((color) => (
        <ColorSquare
          style={{ backgroundColor: `${color}` }}
          className={valueColors[color] === values[colorType] ? "active" : ""}
          onClick={() => onChange(colorType, valueColors[color])}
        />
      ))}
    </div>
  );
}

const ColorSquare = styled.div`
  width: 30px;
  height: 30px;
  margin: 0 5px 5px 5px;
  display: inline-block;
  cursor: pointer;
  border: 2px solid transparent;
  transition: 0.25s ease-in-out;
  &.active {
    border-color: black;
  }
`;
