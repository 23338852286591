import React from "react";
import { Card, Select } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  generatePath,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import DeskLogo from "../../svgs/logoB.svg";
import Icon from "../Icon";

export const BackgroundDiv = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  background: linear-gradient(
      ${(props) => props.bgTint || "rgba(15, 24, 65, 0.65)"},
      ${(props) => props.bgTint || "rgba(15, 24, 65, 0.65)"}
    ),
    url(${(props) => props.bgImg || "img/escritorio-hall-blur5px.png"});
  background-color: #0f1841;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const CenterDiv = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

export const ContainerDiv = styled.div`
  margin: auto;
`;

export const AuthenticationDiv = styled.div`
  padding-top: 17px;
  max-width: 450px;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: rgba(3, 10, 43, 0.85);
`;

export const ContentDiv = styled.div`
  padding: ${(props) => props.padding || "25px 30px 0px"};
  width: 100%;
  margin-bottom: 1.8em;
`;

export const StyledCard = styled(Card)`
  display: inline;
  border-width: 0px;
  border-radius: 10px;
  background: #ffffff;
  /* box-shadow: 2px 3px 5px rgba(31, 35, 56, 0.6); */

  .ant-card-body {
    padding: 20px;
    width: 100%;
    height: 100%;
  }
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 2.5em;
  font-size: 15px;
  :first-child {
    margin-top: 1em;
    margin-bottom: 10px;
  }
`;
export function AuthenticationHeader() {
  const { t } = useTranslation();
  return (
    <HeaderDiv>
      <span>
        <img src={DeskLogo} alt="Deskmy Logo" style={{ width: "120px" }} />
      </span>
      <span className="m-t:.8">{t("AuthenticationHeader.title")}</span>
    </HeaderDiv>
  );
}

const BottomDiv = styled.div`
  font-weight: 600;
  font-size: 14px;
  background-color: rgba(61, 69, 105, 0.5);
  padding: 10px 30px;
  width: 100%;
`;
export function AuthenticationFooter(props) {
  return (
    <BottomDiv>
      <div>{props.children}</div>
    </BottomDiv>
  );
}

function LanguageSelector() {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { i18n } = useTranslation();

  const handleChange = (v) => {
    history.push(
      generatePath(match.path, { ...match.params, locale: v }).concat(
        location.search
      )
    );
  };

  return (
    <div className="bg:black">
      <Select
        placeholder="Selecione"
        value={i18n.language}
        bordered={false}
        className="c:white"
        onChange={handleChange}
      >
        <Select.Option value="pt">
          <Icon type="brazil" className="m-r:.8" />
          Português
        </Select.Option>
        <Select.Option value="en">
          <Icon type="usa" className="m-r:.8" />
          English
        </Select.Option>
      </Select>
    </div>
  );
}

export default function Authentication({
  centerDivClassName,
  authDivClassName,
  authDivStyle = {},
  contentDivPadding,
  footer,
  noHeader,
  noFooter,
  bgImg,
  bgTint,
  logo,
  children,
}) {
  return (
    <BackgroundDiv
      bgImg={bgImg}
      bgTint={bgTint}
      style={{ backdropFilter: "blur(2px)" }}
    >
      <CenterDiv className={centerDivClassName} style={{ width: "100%" }}>
        <ContainerDiv style={{ width: "100%" }}>
          {logo && logo}
          <AuthenticationDiv
            className={authDivClassName}
            style={{ margin: "0 auto", ...authDivStyle }}
          >
            {!noHeader && <AuthenticationHeader />}
            <ContentDiv padding={contentDivPadding}>{children}</ContentDiv>
            {!noFooter && <AuthenticationFooter>{footer}</AuthenticationFooter>}
          </AuthenticationDiv>
        </ContainerDiv>
        <div className="pos:a left:1 top:1">
          <LanguageSelector />
        </div>
      </CenterDiv>
    </BackgroundDiv>
  );
}
