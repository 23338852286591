import { Form } from "antd";
import React from "react";
import { Trans } from "react-i18next";

import api from "../../api/api";
import useAuth from "../../contexts/auth";
import AvatarEditorInput from "../AvatarEditor/AvatarEditorInput";
import OnboardingCard from "./OnboardingCard";

export default function AvatarStep({ onSubmit, footer }) {
  const { user, update } = useAuth();
  const avatar = user.avatar;

  const handleSubmit = (values) =>
    onSubmit(async () => {
      try {
        await api.patch(`/users/${user.id}/`, {
          avatar: values.avatar,
        });
        update();
      } catch (e) {
        throw e;
      }
    });

  return (
    <Form onFinish={handleSubmit}>
      <div className="c:bright-blue f-f:heebo f-w:400 f:2 m-t:1 m-b:4">
        <Trans i18nKey="Onboarding.step-2.title"/>
      </div>
      <OnboardingCard style={{ maxWidth: 1000 }}>
        <Form.Item noStyle name="avatar" initialValue={avatar}>
          <AvatarEditorInput />
        </Form.Item>
        {footer}
      </OnboardingCard>
    </Form>
  );
}
