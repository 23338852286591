import React from "react";
import styled from "styled-components";
import { Button as AButton } from "antd";

import Icon from "./Icon";
import Link from "./Link";

const StyledButton = styled(AButton)`
  /* text-transform: uppercase; */

  &.btn-secondary {
    background: #aaf0e7;
  }

  &.ant-btn-lg {
    font-weight: 500;
    font-size: 0.9rem;
  }

  &.btn-transparent {
    background: #00000033;
    border-width: 0;
    color: white;
    &:hover {
      background: #00000066;
    }
  }

  &.btn-yellow {
    background: var(--yellow);
    color: black;
    border-width: 0;
  }
  &.btn-purple {
    background: #373f66;
    color: white;
    border-width: 0;
  }
  &.btn-red {
    background: red;
    color: white;
    border-width: 0;
  }
  &.btn-transparent-red {
    background: rgba(249, 8, 8, 0.5);
    color: black;
    border-width: 0;
  }

  &.btn-blue {
    background: var(--blue);
    color: white;
    border-width: 0;
  }

  &.btn-grey {
    background: #aaadbb;
    color: black;
    border-width: 0;
  }

  &.btn-bright-blue {
    background: #aaf0e7;
    color: #1e1e20;
    border-width: 0;
  }

  &.btn-login {
    background-color: var(--yellow);
    color: black;
    border-width: 0;
    width: 100%;
    opacity: 1;
    font-weight: 500;
    font-size: 13px;
    &:hover {
      background-color: #ffd030;
      opacity: 0.8;
      transition: opacity 5s ease-in-out;
    }
  }

  &.btn-register {
    background-color: #aaf0e7;
    align-items: center;
    justify-content: center;
    color: black;
    width: 100%;
    margin-bottom: 15px;
    border: none;
    font-size: 13px;
    font-weight: 500;
    opacity: 1;
    svg {
      margin-right: 5px;
    }
    &:hover {
      background-color: #aaf0e7;
      opacity: 0.8;
      transition: opacity 5s ease-in-out;
    }
  }
  &.shadowed {
    box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);
  }
`;

function Button({ icon, className, type, loading, ...props }, ref) {
  switch (type) {
    case "transparent":
      className += " btn-transparent";
      type = undefined;
      break;
    case "yellow":
      className += " btn-yellow";
      type = "primary";
      break;
    case "yellow-shadowed":
      className += " btn-yellow";
      className += " shadowed";
      type = "primary";
      break;
    case "purple":
      className += " btn-purple";
      type = "primary";
      break;
    case "red":
      className += " btn-red";
      type = "primary";
      break;
    case "red-shadowed":
      className += " btn-red";
      className += " shadowed";
      type = "primary";
      break;
    case "transparent-red":
      className += " btn-transparent-red";
      type = "primary";
      break;
    case "blue":
      className += " btn-blue";
      type = "primary";
      break;
    case "grey":
      className += " btn-grey";
      type = "primary";
      break;
    case "bright-blue":
      className += " btn-bright-blue";
      type = "primary";
      break;
    case "bright-blue-shadowed":
      className += " btn-bright-blue";
      className += " shadowed";
      type = "primary";
      break;

    case "secondary":
      className += " btn-secondary";
      type = undefined;
      break;
    case "login":
      className += " btn-login";
      type = "primary";
      break;
    case "register":
      className += " btn-register";
      type = "primary";
      break;
    default:
      break;
  }
  return (
    <StyledButton
      className={className}
      type={type}
      icon={
        icon ? (
          typeof icon === "string" ? (
            <Icon type={icon} className="m-r:.7" />
          ) : (
            icon
          )
        ) : undefined
      }
      loading={loading}
      ref={ref}
      {...props}
    />
  );
}

export default React.forwardRef(Button);

export const ButtonLink = React.forwardRef(
  ({ to, type, size, className, ...props }, ref) => {
    return (
      <Link to={to} className={className} ref={ref}>
        <Button type={type} size={size} {...props} />
      </Link>
    );
  }
);
