import React, { useState, useEffect } from "react";
import { Form, Result, message } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Authentication from "../../components/Authentication";
import { FormItem } from "../../components/Authentication/Form";
import Button, { ButtonLink } from "../../components/Button";
import api from "../../api/api";
import {
  requiredRule,
  confirmPasswordRules,
  mapErrors,
} from "../../utils/forms";
import Icon from "../../components/Icon";

export function ResetPassword() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  let history = useHistory();

  const requestPasswordReset = async (data) => {
    try {
      await api.post("/auth/rest-auth/password/reset/", data);
      setSubmitted(true);
    } catch (e) {
      message.error(t("feedback.generic.error"));
    }
    setLoading(false);
  };

  const handleSendEmail = (data) => {
    setLoading(true);
    requestPasswordReset(data);
  };

  return (
    <Authentication
      centerDivClassName="m-y:5"
      footer={
        <div onClick={() => history.goBack()}>
          <div>
            <Icon type="arrowLeft" className="c:yellow" />{" "}
            <span className="c:yellow">{t("return.label")}</span>
          </div>
        </div>
      }
    >
      {submitted ? (
        <Result
          className="m-t:.5 p:0"
          status="success"
          title={<h3 className="c:white">{t("email-sent.label")}</h3>}
          subTitle={
            <p className="f:1 c:white">{t("ResetPassword.result.success")}</p>
          }
        />
      ) : (
        <Form layout="vertical" onFinish={handleSendEmail} requiredMark={false}>
          <FormItem
            label="EMAIL"
            name="email"
            placeholder={t("form.input.placeholder.your-email")}
            rules={[requiredRule]}
          />

          <Button type="login" htmlType="submit" loading={loading}>
            {t("recover-account.label")}
          </Button>
        </Form>
      )}
    </Authentication>
  );
}

export function ResetPasswordValid() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState();
  const [query, setQuery] = useState(null);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      await api.post("/auth/rest-auth/password/reset/confirm/", {
        ...values,
        ...query,
      });
      setSubmitted(true);
    } catch (e) {
      if (e?.response?.status === 400 && e.response.data) {
        if (e.response.data.token) setError(e.response.data);
        else mapErrors(form, e.response.data);
      }
      message.error(t("feedback.generic.error"));
    }
    setLoading(false);
  };

  let params = useParams();
  useEffect(() => {
    setQuery(params);
  }, [params]);

  return (
    <Authentication
      centerDivClassName="p-y:0 m-y:5"
      style={{ width: "100%" }}
      noFooter
    >
      {submitted || error ? (
        <Result
          className="m-t:.5 p:0"
          status={submitted ? "success" : "error"}
          title={
            submitted ? (
              <h3 className="c:white">{t("ResePassword.result.success2")}</h3>
            ) : (
              <h3 className="c:white">{t("invalid-token.label")}</h3>
            )
          }
          subTitle={
            <p className="f:1 m-b:0 c:white">
              {submitted
                ? t("ResetPassword.result.success.description2")
                : t("ResetPassword.result.error.description2")}
            </p>
          }
          extra={
            submitted ? (
              <ButtonLink to={"/login"} type={"login"}>
                Login
              </ButtonLink>
            ) : (
              <ButtonLink to={"/recuperar-conta"} type={"login"}>
                {t("ResetPassword.redirect-button")}
              </ButtonLink>
            )
          }
        />
      ) : (
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          requiredMark={false}
          className="t-a:l"
        >
          <FormItem
            type="password"
            label={t("password.label").toLocaleUpperCase()}
            name="new_password1"
            placeholder={t("ResetPassword.input.password1")}
            rules={[requiredRule]}
            visibilityToggle={false}
            minLength={6}
          />
          <FormItem
            type="password"
            label={t("confirm-password.label").toLocaleUpperCase()}
            name="new_password2"
            placeholder={t("ResetPassword.input.password2")}
            dependencies={["new_password1"]}
            rules={confirmPasswordRules("new_password1")}
            visibilityToggle={false}
          />

          <Button type={"login"} onClick={form.submit} loading={loading}>
            {t("change-password.label")}
          </Button>
        </Form>
      )}
    </Authentication>
  );
}
