import React from "react";
import { Link as RRLink, useLocation } from "react-router-dom";

export default function Link({ isOverlay, ...props }) {
  const location = useLocation();
  if (isOverlay) {
    if (typeof props.to === "string")
      props.to = { pathname: props.to, state: { background: location } };
    else props.to.state = { ...(props.to.state || {}), background: location };
  }

  return <RRLink {...props} />;
}
