import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import useSWR from "swr";
import moment from "moment";

import Icon from "../components/Icon";
import { get } from "../api/api";
import Button from "./Button";
import useAuth from "../contexts/auth";

export default function withPlanVerification(Route) {
  return ({ ...props }) => {
    const { user } = useAuth();
    const { data: planInfo = {} } = useSWR(
      "/plans/summarized_plan_info/",
      get,
      { revalidateOnFocus: false }
    );

    const paymentDateDiff =
      planInfo && moment().diff(moment(planInfo.plan_payday), "days");

    const notPayed = planInfo?.status === "NOT_PAID";

    const planExpired =
      planInfo &&
      planInfo !== "ACTIVE" &&
      ((planInfo.days_remaining && planInfo.days_remaining < 0) ||
        (notPayed && paymentDateDiff >= 5));

    if (planExpired === undefined) return false;
    // Caso o plano não expirou
    if (!planExpired)
      return (
        <>
          {notPayed && paymentDateDiff > 0 && user?.access_type === "ADMIN" && (
            <NotPayedNotification />
          )}
          <Route {...props} />
        </>
      );
    return <Redirect to="/plan_expired" />;
  };
}

function NotPayedNotification() {
  const [messageShown, setMessageShown] = useState(true);
  if (messageShown)
    return (
      <div className="p-y:.6 p-x:1 t-a:c" style={{ background: "#f1f1f1" }}>
        <span className="f-w:500">
          Pagamento de assinatura não identificado.{" "}
          <a
            target="_blank"
            href={process.env.REACT_APP_PAYMENT_URL}
            rel="noopener noreferrer"
          >
            Gerenciar assinatura
          </a>
        </span>
        <Button
          type="text"
          className="fl:r"
          icon={<Icon type="close" />}
          size="small"
          onClick={() => setMessageShown(false)}
        />
      </div>
    );
  return false;
}
