import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { SWRConfig } from "swr";

import ptBr from "antd/lib/locale/pt_BR";

import RootRouter from "./Routers/Root";

import "./styles/antd-build.css";
import "./styles/shed.css";
import "./styles/global.scss";
import ErrorBoundary from "./components/ErrorBoundary";
import MaintenancePage from "./components/MaintenancePage";

function App() {
  const { i18n } = useTranslation();

  if (process.env.REACT_APP_MAINTENANCE) return <MaintenancePage />;
  return (
    <ErrorBoundary>
      <div className="App">
        <SWRConfig value={{ revalidateOnFocus: false }}>
          <ConfigProvider locale={i18n.language === "pt" ? ptBr : undefined}>
            <BrowserRouter>
              <RootRouter />
            </BrowserRouter>
          </ConfigProvider>
        </SWRConfig>
      </div>
    </ErrorBoundary>
  );
}

export default App;
