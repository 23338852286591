// import "./i18n";

import React, { Component, useEffect } from "react";

import { useTranslation } from "react-i18next";
import moment from "moment";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { Spin } from "antd";
// import PageLoader from '../../components/common/PageLoader';
import "moment/locale/pt-br";

export function withLocaleRoute(Component) {
  return (props) => {
    const { i18n } = useTranslation();

    const { pathname, search } = props.location;
    useEffect(() => {
      const switchLanguage = async () => {
        if (props.match.params.locale) {
          if (
            i18n.language !== props.match.params.locale &&
            !i18n.isLanguageChangingTo
          )
            await i18n.changeLanguage(props.match.params.locale);
        } else props.history.push(`/${i18n.language}${pathname}${search}`);
      };
      switchLanguage();
    }, [props.match.params.locale, i18n, props.history, pathname, search]);

    return <Component {...props} />;
  };
}

export default class Internationalization extends Component {
  state = { loaded: false };
  async componentDidMount() {
    try {
      await i18n
        .use(HttpApi)
        .use(initReactI18next)
        .init({
          // lng: window.navigator.language.slice(0, 2),
          lng: window.navigator.language.slice(0, 2).toLocaleLowerCase(),
          backend: {
            /* translation file path */
            loadPath: "/locales/{{lng}}.json",
          },
          fallbackLng: "pt",
          // fallbackLng: "en",
          debug: false,
          /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */

          keySeparator: false,
          interpolation: {
            escapeValue: false,
            formatSeparator: ",",
          },
          react: {
            wait: true,
            useSuspense: false,
          },
        });
      this.setState({ loaded: true });
      if (i18n.language === "pt") moment.locale("pt-br");
      i18n.on("languageChanged", (lang) => {
        moment.locale(lang === "pt" ? "pt-br" : "en-us");
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { children } = this.props;
    const { loaded } = this.state;

    return loaded ? (
      children
    ) : (
      // <PageLoader showText={false} />
      <Spin />
    );
  }
}
