import React from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";

import authRoutes from "./Auth";
import invitedRoutes from "./Invited";

export default function Root() {
  const location = useLocation();
  const background = location.state && location.state.background;

  return (
    <>
      <Switch location={background || location}>
        <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />
        {invitedRoutes}
        {authRoutes}
      </Switch>
      {background && <Switch>{authRoutes}</Switch>}
    </>
  );
}
