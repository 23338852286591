import React, { useState } from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { data } from "./library";
import Icon from "../Icon";
import Palette from "./Palette";

const { TabPane } = Tabs;

export default function PartSelector({ onChange, values = {} }) {
  const { t } = useTranslation();
  const [tab, setTab] = useState();

  const tabName = [
    t("hair.label"),
    t("accessories.label"),
    t("skin.label"),
    t("mouth.label"),
    t("beard.label"),
    t("eyes.label"),
    t("eyebrows.label"),
    t("clothes.label"),
  ];

  // renderiza e importa de acordo com a chave
  return (
    <Tabs
      activeKey={tab}
      type="line"
      animated={{ inkBar: false, tabPane: true }}
      renderTabBar={renderTabBar}
      onChange={setTab}
    >
      {Object.keys(data).map((keyValue, index) => {
        return (
          <TabPane tab={tabName[index]} key={keyValue}>
            <div>
              {data[keyValue].map((item) => (
                <PartItem
                  onSelect={() => onChange(keyValue, item.name)}
                  key={item.name}
                  part={keyValue}
                  item={item}
                  active={values[keyValue] === item.name}
                />
              ))}

              {["topType", "facialHairType", "clotheType"].includes(
                keyValue
              ) && (
                <div className="m-t:2">
                  <Palette
                    onChange={onChange}
                    type={keyValue}
                    values={values}
                  />
                </div>
              )}
            </div>
          </TabPane>
        );
      })}
    </Tabs>
  );
}

PartSelector.propTypes = { values: PropTypes.object, onChange: PropTypes.func };

function PartItem({ onSelect, item, part, active }) {
  return (
    <CustomSquare onClick={onSelect} className={active ? "active" : ""}>
      {item.name === "Blank" || item.empty ? (
        <div
          className="d:f a-i:c j-c:c"
          style={{ width: "100%", height: "100%" }}
        >
          <Icon type="cancel" className="f:7 c:black.6" />
        </div>
      ) : (
        <img src={`/Avatares/${part}/${item["path"]}`} alt="" />
      )}
    </CustomSquare>
  );
}

function renderTabBar(props, DefaultTabBar) {
  return (
    <CustomTabBar>
      <DefaultTabBar {...props} style={{ ...props.style }} />
    </CustomTabBar>
  );
}

const CustomSquare = styled.div`
  width: 90px;
  height: 90px;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  border-radius: var(--base-radius);
  border: 2px solid transparent;
  margin: 5px;
  transition: 0.25s ease-in-out;
  vertical-align: bottom;
  &.active {
    border-color: #373f66;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: fit;
  }
`;

const CustomTabBar = styled.div`
  .ant-tabs-nav {
    margin-left: auto;
  }
  .ant-tabs-tab {
    margin: 0 10px 0 0;
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    div {
      background-color: #373f66;
      color: white;
    }
  }
  .ant-tabs-tab-btn {
    background-color: #aaf0e7;
    padding: 0 13px;
    border-radius: var(--base-radius);
  }
  .ant-tabs-ink-bar {
    display: none;
  }
`;
