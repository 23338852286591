import React from "react";
import { Trans } from "react-i18next";

export const requiredRule = {
  message: <Trans i18nKey="form.item-required" />,
  required: true,
};
export const emailRule = {
  type: "email",
  message: <Trans i18nKey="form.input.wrong-email" />,
};
export const emailRules = [emailRule, requiredRule];
export const confirmPasswordRules = (password_label) => {
  return [
    requiredRule,
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue(password_label) === value) {
          return Promise.resolve();
        }
        return Promise.reject(<Trans i18nKey="passwords-match.label" />);
      },
    }),
  ];
};
export const agreementRule = {
  validator: (_, value) =>
    value
      ? Promise.resolve()
      : Promise.reject(
          <Trans i18nKey="terms-required"/>
        ),
};

export const mapErrors = (form, data) =>
  form.setFields(
    Object.keys(data).map((k) => ({
      name: k,
      errors: Array.isArray(data[k]) ? data[k] : [data[k]],
    }))
  );
